import { H1_FlexColumn } from "app/components/_Infrastructure/layout/flexcolumn";
import { H1_FlexRow } from "app/components/_Infrastructure/layout/flexrow";
import { LayoutAsset, Voice } from "app/types";
import { useAppSelector } from "app/hooks";
import * as characterSelectors from "app/store/selectorsV2/character.selectors";
import * as voiceSelectors from "app/store/selectorsV2/voices.selectors";
import styled from "styled-components";
import { ThemeMode } from "app/utils/theme";
import { NameText } from "app/components/common/DraftsAndFolders/NameText";
import { replaceFlagSeparator } from "app/utils/helpers";
import React from "react";
import useDrawer, { Drawer } from "app/hooks/useDrawer";

const EntityBorderFlexRow = styled(H1_FlexRow)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.gray3};
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: ${({ theme }) => (theme.mode === ThemeMode.Light ? theme.gray1 : theme.gray3)};

  &:hover {
    background-color: ${({ theme }) => theme.gray2};
  }
`;

const FlagImg = styled.img`
  border-radius: 10px;
  width: 42px;
  height: 32px;
  object-fit: cover;
`;

const CharacterContainerFlexRow = styled(H1_FlexRow)`
  border-radius: 4px;
`;

const CharacterImg = styled.img`
  border-radius: 10px;
  width: 155px;
  height: 141px;
  object-fit: cover;
`;

export interface AvatarsAndVoicesDrawerSingleElementProps {
  characterAsset: LayoutAsset;
}

const AvatarsAndVoicesDrawerSingleElement = ({
  characterAsset
}: AvatarsAndVoicesDrawerSingleElementProps) => {
  const { openDrawer } = useDrawer();
  const selectedCharacter = useAppSelector((state) =>
    characterSelectors.getDrawerCharacterBySelectedScene(state, characterAsset.key)
  );

  const voiceAssetKey = characterAsset?.restrictions?.voice_key || "voice";
  const selectedVoice: Voice | undefined = useAppSelector((state) =>
    voiceSelectors.getSelectedVoiceForDrawer(state, voiceAssetKey)
  );

  const flagSvg = replaceFlagSeparator(selectedVoice?.local);

  const onClickAvatar = () => {
    openDrawer(Drawer.Presenter, {
      assetKey: characterAsset.key,
      voiceAssetKey,
      isSwitchingAvatar: true
    });
  };

  const onClickVoice = () => {
    openDrawer(Drawer.Voices, {
      characterAssetKey: characterAsset.key,
      assetKey: voiceAssetKey,
      isSwitchingAvatar: true
    });
  };

  return (
    <H1_FlexColumn width="155px" gap="14px">
      <EntityBorderFlexRow
        data-auto-id="avatar-drawer-selected-avatar"
        flex="0 0 102px"
        height="141px"
        padding="0"
        gap="10px"
        align="center"
        onClick={onClickAvatar}
      >
        <CharacterContainerFlexRow overflow="hidden" width="155px" height="141px" flex="0 0 155px">
          <CharacterImg
            src={(selectedCharacter?.thumbnails?.panel.image as string) || selectedCharacter?.image}
          />
        </CharacterContainerFlexRow>
        <NameText $maxWidth="155px" ellipsis={{ tooltip: selectedCharacter?.title }}>
          {selectedCharacter?.title}
        </NameText>
      </EntityBorderFlexRow>
      <EntityBorderFlexRow
        flex="0 0 48px"
        height="48px"
        padding="7px 10px"
        gap="5px"
        align="center"
        onClick={onClickVoice}
      >
        <FlagImg src={`https://flagcdn.com/${flagSvg}.svg`} />
        <NameText $maxWidth="155px" ellipsis={{ tooltip: selectedVoice?.display_name }}>
          {selectedVoice?.display_name}
        </NameText>
      </EntityBorderFlexRow>
    </H1_FlexColumn>
  );
};

export default AvatarsAndVoicesDrawerSingleElement;
