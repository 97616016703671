import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { set } from "lodash-es";
import asyncThunks from "app/store/thunks/scenes.thunk";
import { fetchingStatus } from "app/utils/helpers";
import {
  DefaultAddScenePosition,
  FetchStatus,
  GeneratedTranscriptResponse,
  ImageFitModel,
  InternalScene,
  Scene,
  SceneTabs,
  SynthesisMarkupLanguage,
  VoiceSpeechConfig
} from "app/types";
import { scenesAdapter } from "app/store/adapters/adapters";

interface ScenesState {
  scenesStatus: FetchStatus;
  patchScenesStatus: FetchStatus;
  createSceneStatus: FetchStatus;
  generateTranscriptStatus: FetchStatus;
  genrateSceneAssetStatus: Record<string, FetchStatus>;
  generateTranscript?: GeneratedTranscriptResponse;
  augmentedSceneStatus: FetchStatus;
  wandOverlaySceneId?: string;
  deleteSceneStatus: FetchStatus;
  framePreviewLoading: Record<string, { progress?: number; orderId?: string; loading: boolean }>;
  selectedSceneId: string | undefined;
  selectedSceneTab: SceneTabs;
  imageFit: ImageFitModel;
  outsideChangeSML: Record<any, SynthesisMarkupLanguage[]>;
  scenesChanged: boolean;
  tempScenes: Scene[];
  lastDeletedScene?: Scene;
  lastDeletedParentId?: string;
}

export const scenesSlice = createSlice({
  name: "Scenes",
  initialState: scenesAdapter.getInitialState<ScenesState>({
    scenesChanged: false,
    patchScenesStatus: fetchingStatus.idle as FetchStatus,
    scenesStatus: fetchingStatus.idle as FetchStatus,
    createSceneStatus: fetchingStatus.idle as FetchStatus,
    generateTranscriptStatus: fetchingStatus.idle as FetchStatus,
    genrateSceneAssetStatus: {},
    augmentedSceneStatus: fetchingStatus.idle as FetchStatus,
    deleteSceneStatus: fetchingStatus.idle as FetchStatus,
    framePreviewLoading: {},
    selectedSceneId: undefined,
    selectedSceneTab: SceneTabs.Content,
    imageFit: {},
    outsideChangeSML: {},
    tempScenes: []
  }),
  reducers: {
    cleanLastDeletedScene(state) {
      state.lastDeletedScene = undefined;
      state.lastDeletedParentId = undefined;
      return state;
    },
    setScenesChanged(state, action) {
      state.scenesChanged = action.payload;
      return state;
    },
    saveScenesToTemp: (state) => {
      state.tempScenes = state.ids.map((id) => state.entities[id] as Scene);
      return state;
    },
    restoreTempScenes: (state) => {
      scenesAdapter.setAll(state, state.tempScenes);
      return state;
    },
    cleanScenes: (state) => {
      state.selectedSceneTab = SceneTabs.Content;
      state.framePreviewLoading = {};
      scenesAdapter.removeAll(state);
      state.lastDeletedScene = undefined;
      state.lastDeletedParentId = undefined;
      return state;
    },
    updateImageFit: (state, action: PayloadAction<Partial<ImageFitModel>>) => {
      const initialUpdate = Object.keys(state.imageFit).length ? false : true;
      state.imageFit = { ...state.imageFit, ...action.payload, initialUpdate };
      return state;
    },
    cleanImageFit: (state) => {
      state.imageFit = {};
      return state;
    },
    updateAugmentedSceneStatusToIdle(state) {
      state.augmentedSceneStatus = fetchingStatus.idle;
      return state;
    },
    updateGenerateSceneAssetsStatusToIdle(state, action: PayloadAction<string>) {
      state.genrateSceneAssetStatus[action.payload] = fetchingStatus.idle;
      return state;
    },
    updateGeneratedTranscriptStatusToIdle(state) {
      state.generateTranscriptStatus = fetchingStatus.idle;
      return state;
    },
    updateWandOverlaySceneId(state, action: PayloadAction<string | undefined>) {
      state.wandOverlaySceneId = action.payload;
      return state;
    },
    updateDeleteStatusToIdle: (state) => {
      state.deleteSceneStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateSceneTab: (state, action: PayloadAction<SceneTabs>) => {
      state.selectedSceneTab = action.payload;
      return state;
    },
    updateStateToIdle: (state) => {
      state.scenesStatus = fetchingStatus.idle as FetchStatus;
      return state;
    },
    updateCurrentSceneVoiceAdjustment(
      state,
      action: PayloadAction<{
        sceneId: string;
        assetKey: string;
        voiceAdjustments: VoiceSpeechConfig[];
      }>
    ) {
      const { sceneId, voiceAdjustments, assetKey } = action.payload;
      if (state.entities[sceneId]?.attributes?.voice?.[assetKey]) {
        state.entities[sceneId]!.attributes!.voice![assetKey]!.voice_adjustments = voiceAdjustments;
      }
      return state;
    },
    setFramePreviewLoading: (
      state,
      action: PayloadAction<{
        sceneId: string;
        progress?: number;
        orderId?: string;
        loading: boolean;
      }>
    ) => {
      state.framePreviewLoading[action.payload.sceneId] = {
        progress: action.payload.progress,
        loading: action.payload.loading,
        orderId: action.payload.orderId
      };
      return state;
    },
    setSelectedSceneId: (state, action) => {
      state.selectedSceneId = action.payload;
      return state;
    },
    setAllScenes: (state, action) => {
      scenesAdapter.setAll(state, action.payload);
    },
    addOneScene: (state, action) => {
      scenesAdapter.upsertOne(state, action.payload);
      return state;
    },
    setScenesAndSelectId: (state, action) => {
      scenesAdapter.setAll(state, action.payload.scenes);
      state.selectedSceneId = action.payload.id;

      return state;
    },
    updateAllScenes: (state, action) => {
      const updates = action.payload.map((scenePayload: Scene) => ({
        id: scenePayload.id,
        changes: scenePayload
      }));
      scenesAdapter.updateMany(state, updates);
    },
    updateSceneTranscript: (state, action: PayloadAction<{ sceneId: string; value: string }>) => {
      const { sceneId, value } = action.payload;
      if (state.entities[sceneId]?.attributes?.text?.transcript?.text) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.entities[sceneId]!.attributes.text!.transcript!.text = value;
      }

      return state;
    },
    updateOutsideChangeSynthesisMarkupLanguage: (
      state,
      action: PayloadAction<Record<string, SynthesisMarkupLanguage[]>>
    ) => {
      state.outsideChangeSML = action.payload;
      Object.entries(action.payload).forEach(([sceneId, sml]) => {
        if (
          state.entities[sceneId]?.attributes?.text?.transcript?.synthesis_markup_language?.nodes
        ) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          state.entities[sceneId]!.attributes.text!.transcript!.synthesis_markup_language!.nodes =
            sml;
        }
      });

      return state;
    },
    removeScene: (state, action: PayloadAction<{ sceneId: string }>) => {
      scenesAdapter.removeOne(state, action.payload.sceneId);
    },
    setScenesCharacterAndVoiceAsGlobal: (
      state,
      action: PayloadAction<{
        voiceId: string;
        characterId: string;
        voiceAdjustment?: VoiceSpeechConfig[];
      }>
    ) => {
      const { voiceId, characterId, voiceAdjustment } = action.payload;
      for (const id in state.entities) {
        const scene = state.entities[id];
        if (scene) {
          scene.attributes.voice = {
            voice: {
              voice_id: voiceId,
              voice_adjustments: voiceAdjustment
            }
          };
          scene.attributes.character = {
            character: {
              character_id: characterId
            }
          };
        }
      }
      return state;
    },
    reorderScenes: (state, action: PayloadAction<{ startIndex: number; endIndex: number }>) => {
      const { startIndex, endIndex } = action.payload;
      const result = Array.from(state.ids);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      state.ids = result;
      return state;
    },
    updateScenes: (state, action: PayloadAction<InternalScene[]>) => {
      scenesAdapter.setAll(state, action.payload);
      return state;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(asyncThunks.getDraftsScenesRequest.pending, (state) => {
      state.scenesStatus = fetchingStatus.loading as FetchStatus;
      state.lastDeletedScene = undefined;
      state.lastDeletedParentId = undefined;
    });
    builder.addCase(asyncThunks.getDraftsScenesRequest.fulfilled, (state, action) => {
      const { payload } = action;
      scenesAdapter.setAll(state, payload);
      state.scenesStatus = fetchingStatus.succeeded as FetchStatus;
      if (payload.length > 0) {
        state.selectedSceneId = payload[0].id;
      }
    });
    builder.addCase(asyncThunks.getDraftsScenesRequest.rejected, (state) => {
      state.scenesStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.addDraftSceneRequest.fulfilled, (state, action) => {
      const { parentId, defaultPosition } = action.meta.arg;
      if (parentId) {
        const index = state.ids.findIndex((curId) => curId === parentId);
        state.ids.splice(index + 1, 0, action.payload.id);
        state.entities[action.payload.id] = action.payload;
      } else if (defaultPosition) {
        if (defaultPosition === DefaultAddScenePosition.First) {
          state.ids.unshift(action.payload.id);
          state.entities[action.payload.id] = action.payload;
        } else {
          scenesAdapter.addOne(state, action.payload);
        }
      } else {
        scenesAdapter.addOne(state, action.payload);
      }
      state.createSceneStatus = fetchingStatus.succeeded as FetchStatus;
      state.selectedSceneId = action.payload.id;
    });

    builder.addCase(asyncThunks.addDraftSceneRequest.rejected, (state) => {
      state.createSceneStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.addDraftSceneRequest.pending, (state) => {
      state.createSceneStatus = fetchingStatus.loading as FetchStatus;
    });

    builder.addCase(asyncThunks.duplicateSceneRequest.fulfilled, (state, action) => {
      const { parentId } = action.meta.arg;
      if (parentId) {
        const index = state.ids.findIndex((curId) => curId === parentId);
        state.ids.splice(index + 1, 0, action.payload.id);
        state.entities[action.payload.id] = action.payload;
      }
      state.createSceneStatus = fetchingStatus.succeeded as FetchStatus;
      state.selectedSceneId = action.payload.id;
    });

    builder.addCase(asyncThunks.duplicateSceneRequest.rejected, (state) => {
      state.createSceneStatus = fetchingStatus.failed as FetchStatus;
    });

    builder.addCase(asyncThunks.duplicateSceneRequest.pending, (state) => {
      state.createSceneStatus = fetchingStatus.loading as FetchStatus;
    });

    builder.addCase(asyncThunks.patchSceneRequest.pending, (state, action) => {
      state.patchScenesStatus = fetchingStatus.loading as FetchStatus;
      const { sceneId } = action.meta.arg;
      const { operations } = action.meta.arg;
      // eslint-disable-next-line no-restricted-syntax
      for (const operation of operations) {
        if (operation.op === "generate") {
          state.genrateSceneAssetStatus[`${sceneId}-${operation.path}`] = fetchingStatus.loading;
        } else {
          set(state.entities[sceneId] as Scene, operation.path, operation.value);
        }
      }
    });
    builder.addCase(asyncThunks.patchSceneRequest.rejected, (state, action) => {
      state.patchScenesStatus = fetchingStatus.failed as FetchStatus;
      const { sceneId } = action.meta.arg;

      const { operations } = action.meta.arg;
      // eslint-disable-next-line no-restricted-syntax
      for (const operation of operations) {
        if (operation.op === "generate" && action.error.status === 402) {
          state.genrateSceneAssetStatus[`${sceneId}-${operation.path}`] = fetchingStatus.idle;
        }
      }
    });

    builder.addCase(asyncThunks.patchSceneRequest.fulfilled, (state) => {
      state.patchScenesStatus = fetchingStatus.succeeded as FetchStatus;
      state.scenesChanged = false;
    });

    builder.addCase(asyncThunks.deleteDraftSceneRequest.pending, (state, action) => {
      state.deleteSceneStatus = fetchingStatus.loading;
      const { sceneId } = action.meta.arg;
      const scenesIds = state.ids;
      const findSceneIndex = scenesIds.findIndex((id) => id === sceneId);
      const selectedSceneIndex = findSceneIndex !== 0 ? findSceneIndex - 1 : 1;
      state.selectedSceneId = scenesIds[selectedSceneIndex] as string;
      state.lastDeletedScene = state.entities[sceneId] as Scene;

      state.lastDeletedParentId = state.entities[state.ids[findSceneIndex - 1]]?.id;
      scenesAdapter.removeOne(state, sceneId);
    });
    builder.addCase(asyncThunks.deleteDraftSceneRequest.fulfilled, (state) => {
      state.deleteSceneStatus = fetchingStatus.succeeded;
    });
    builder.addCase(asyncThunks.deleteDraftSceneRequest.rejected, (state) => {
      state.deleteSceneStatus = fetchingStatus.failed;
    });

    builder.addCase(asyncThunks.patchSceneLayoutRequest.pending, (state, action) => {
      scenesAdapter.updateOne(state, {
        id: action.meta.arg.sceneId,
        changes: { layout_id: action.meta.arg.layoutId }
      });
    });
    builder.addCase(asyncThunks.patchSceneLayoutRequest.fulfilled, (state, action) => {
      scenesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
    });

    builder.addCase(asyncThunks.scenePreviewRequest.pending, (state, action) => {
      const { sceneIds } = action.meta.arg;
      sceneIds.forEach((sceneId) => {
        state.framePreviewLoading[sceneId] = { loading: true };
      });
    });

    builder.addCase(asyncThunks.scenePreviewRequest.rejected, (state, action) => {
      const { sceneIds } = action.meta.arg;
      sceneIds.forEach((sceneId) => {
        state.framePreviewLoading[sceneId] = { loading: false };
      });
    });

    builder.addCase(asyncThunks.scenePreviewRequest.fulfilled, (state, action) => {
      const updates = action.payload.map(({ scene_id, url }) => ({
        id: scene_id,
        changes: { last_preview_url: url }
      }));
      action.payload.forEach(({ scene_id, order_id, cached }) => {
        state.framePreviewLoading[scene_id].orderId = order_id;
        if (cached) {
          state.framePreviewLoading[scene_id].loading = false;
          state.framePreviewLoading[scene_id].progress = 100;
        }
      });

      scenesAdapter.updateMany(state, updates);
    });

    builder.addCase(asyncThunks.moveScene.pending, (state, action) => {
      state.scenesStatus = fetchingStatus.loading as FetchStatus;
      const { parentId, originalSceneIndex, originalParentIndex } = action.meta.arg;
      const scenesIds = state.ids;
      let findParentIndex = originalParentIndex;
      if (originalSceneIndex > originalParentIndex) {
        findParentIndex = parentId ? scenesIds.findIndex((id) => id === parentId) + 1 : 0;
      }
      const element = state.ids[originalSceneIndex];
      state.ids.splice(originalSceneIndex, 1);
      state.ids.splice(findParentIndex, 0, element);
    });
    builder.addCase(asyncThunks.moveScene.fulfilled, (state) => {
      state.scenesStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.moveScene.rejected, (state, action) => {
      state.scenesStatus = fetchingStatus.failed as FetchStatus;
      /** Need a revision in the future */
      const { parentId, originalSceneIndex, originalParentIndex } = action.meta.arg;
      let findParentIndex = originalParentIndex;
      if (originalSceneIndex > originalParentIndex) {
        findParentIndex = parentId ? originalParentIndex + 1 : 0;
        const lowerIndex = Math.min(originalSceneIndex, findParentIndex);
        const higherIndex = Math.max(originalSceneIndex, findParentIndex);
        const lowerIndexScene = state.ids[lowerIndex];
        for (let i = lowerIndex; i < higherIndex; i += 1) {
          state.ids[i] = state.ids[i + 1];
        }
        state.ids[higherIndex] = lowerIndexScene;
      } else {
        const lowerIndex = Math.min(originalSceneIndex, findParentIndex);
        const higherIndex = Math.max(originalSceneIndex, findParentIndex);
        const higherIndexScene = state.ids[higherIndex];
        for (let i = higherIndex; i > lowerIndex; i -= 1) {
          state.ids[i] = state.ids[i - 1];
        }
        state.ids[lowerIndex] = higherIndexScene;
      }
    });

    builder.addCase(asyncThunks.updateSceneWithAugmentedTextRequest.pending, (state, action) => {
      state.wandOverlaySceneId = action.meta.arg.sceneId;
      state.augmentedSceneStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.updateSceneWithAugmentedTextRequest.rejected, (state) => {
      state.augmentedSceneStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.updateSceneWithAugmentedTextRequest.fulfilled, (state, action) => {
      scenesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.augmentedSceneStatus = fetchingStatus.succeeded as FetchStatus;
    });

    builder.addCase(asyncThunks.generateTranscriptFromTopicRequest.pending, (state, action) => {
      state.wandOverlaySceneId = action.meta.arg.sceneId;
      state.generateTranscriptStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.generateTranscriptFromTopicRequest.rejected, (state) => {
      state.generateTranscriptStatus = fetchingStatus.failed as FetchStatus;
    });
    builder.addCase(asyncThunks.generateTranscriptFromTopicRequest.fulfilled, (state, action) => {
      state.generateTranscriptStatus = fetchingStatus.succeeded as FetchStatus;
      state.generateTranscript = action.payload;
      const id = action.meta.arg.sceneId;
      if (action.payload.transcript) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.entities[id]!.attributes!.text!.transcript!.text = action.payload.transcript;
      }
    });

    builder.addCase(asyncThunks.getSceneByIdRequest.pending, (state) => {
      state.scenesStatus = fetchingStatus.loading as FetchStatus;
    });
    builder.addCase(asyncThunks.getSceneByIdRequest.fulfilled, (state, action) => {
      const { payload, meta } = action;
      const updates = payload.map((scenePayload: Scene) => ({
        id: scenePayload.id,
        changes: scenePayload
      }));
      scenesAdapter.updateMany(state, updates);

      // Update genrateSceneAssetStatus for success
      if (meta.arg.isGenerateRebound) {
        meta.arg.paths?.forEach((path) => {
          state.genrateSceneAssetStatus[`${meta.arg.sceneId}-${path}`] = fetchingStatus.succeeded;
        });
      }

      state.scenesStatus = fetchingStatus.succeeded as FetchStatus;
    });
    builder.addCase(asyncThunks.getSceneByIdRequest.rejected, (state, action) => {
      const { meta } = action;
      state.scenesStatus = fetchingStatus.failed as FetchStatus;
      if (meta.arg.isGenerateRebound) {
        meta.arg.paths?.forEach((path) => {
          state.genrateSceneAssetStatus[`${meta.arg.sceneId}-${path}`] = fetchingStatus.failed;
        });
      }
    });
    builder.addCase(asyncThunks.patchVoiceSpeechRequest.pending, (state, action) => {
      const { voiceAdjustments } = action.meta.arg;
      const selectedScene = state.entities[state.selectedSceneId as string];
      if (selectedScene) {
        if (!selectedScene.attributes.voice) {
          selectedScene.attributes.voice = { voice: {} };
        }
        // todo
        selectedScene.attributes.voice.voice = {
          ...selectedScene.attributes.voice.voice,
          voice_adjustments: voiceAdjustments
        };
      }
    });
  }
});

export default scenesSlice.reducer;
export const scenesActions = {
  getDraftsScenesRequest: asyncThunks.getDraftsScenesRequest,
  getSceneByIdRequest: asyncThunks.getSceneByIdRequest,
  addDraftSceneRequest: asyncThunks.addDraftSceneRequest,
  duplicateSceneRequest: asyncThunks.duplicateSceneRequest,
  patchSceneRequest: asyncThunks.patchSceneRequest,
  moveScene: asyncThunks.moveScene,
  deleteDraftSceneRequest: asyncThunks.deleteDraftSceneRequest,
  patchSceneLayout: asyncThunks.patchSceneLayoutRequest,
  scenePreviewRequest: asyncThunks.scenePreviewRequest,
  scenePreviewLocalUpdate: asyncThunks.scenePreviewLocalUpdate,
  updateSceneWithAugmentedTextRequest: asyncThunks.updateSceneWithAugmentedTextRequest,
  generateTranscriptFromTopicRequest: asyncThunks.generateTranscriptFromTopicRequest,
  patchVoiceSpeechRequest: asyncThunks.patchVoiceSpeechRequest,
  ...scenesSlice.actions
};
